/* tslint:disable:no-default-export */

import { Header, Label } from '@opengov/component-library/capital';
import { Link } from 'gatsby';
import React from 'react';

import LayoutHome from '../../components/capital/Layouts/LayoutHome';
import stylesLayout from '../../components/capital/Layouts/Layout.scss';
import styles from '../../components/capital/Layouts/LayoutHome.scss';
import componentsIcon from '../../images/components-white.svg';
import overviewIcon from '../../images/overview-white.svg';
import patternsIcon from '../../images/patterns-white.svg';
import stylesIcon from '../../images/styles-white.svg';

export default () => (
  <LayoutHome>
    <div className={styles.homeGrid}>
      <Link to="/capital/overview" className={styles.homeButton}>
      <div className={styles.homeButtonIcon}>
        <img src={overviewIcon} alt="" role="presentation" />
      </div>
        <span className={styles.homeButtonHeader}>Overview</span>
        <div className={styles.homeButtonDescription}>
          An overview of what the design system is, how it is built and maintained, and how to get started using it.
        </div>
      </Link>
      <Link to="/capital/styles" className={styles.homeButton}>
      <div className={styles.homeButtonIcon}><img src={stylesIcon} alt="" role="presentation" /></div>
      <span className={styles.homeButtonHeader}>Styles</span>
        <div className={styles.homeButtonDescription}>
          Details behind the visual style of CDS UI, and resources for designers and developers to implement it.
        </div>
      </Link>
      <Link to="/capital/components" className={styles.homeButton}>
        <div className={styles.homeButtonIcon}>
          <img src={componentsIcon} alt="" role="presentation" />
        </div>
        <span className={styles.homeButtonHeader}>Components</span>
        <div className={styles.homeButtonDescription}>
          Guidelines for using components and contributing to the component library for designers and developers.
        </div>
      </Link>
      <Link className={styles.homeButtonDisabled}>
      <div className={styles.homeButtonIcon}>
        <img src={patternsIcon} alt="" role="presentation" />
      </div>
      <span className={styles.homeButtonHeader}>Patterns</span>
        <div className={styles.homeButtonDescription}>
          Design patterns and best practices to use as guidelines when building interfaces for any user need.
        </div>
      </Link>
    </div>
    <div className={styles.homeResources}>
      <Header variant="h2" className="websiteH2">Popular resources</Header>
      <div className="columns columns-3">
        <Link className={styles.homeResourceLink} to="/capital/styles/tokens">
          <Label className={styles.homeResourceSection} variant="group" as="span">Styles</Label>
          Token Library
          <span className={styles.homeResourceDescription}>Values and definitions for all tokens defined in Capital.</span>
        </Link>
        <Link className={styles.homeResourceLink} to="/capital/styles/icons">
          <Label className={styles.homeResourceSection} variant="group" as="span">Styles</Label>
          Icon Library
          <span className={styles.homeResourceDescription}>Values and definitions for all icons defined in Capital.</span>
        </Link>
        <Link className={styles.homeResourceLink} to="/capital/overview/glossary">
          <Label className={styles.homeResourceSection} variant="group" as="span">Overview</Label>
          System Glossary
          <span className={styles.homeResourceDescription}>Terms used within and to describe Capital Design System.</span>
        </Link>
      </div>
    </div>
  </LayoutHome>
);
